/*! 2025-4-2 20:17:05 */
[data-v-4287d15e]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.pagination-container[data-v-4287d15e]{background-color:#fff;padding:20px 10px;text-align:right;padding-right:60px}.pagination-container.hidden[data-v-4287d15e]{display:none}.pagination-container.left[data-v-4287d15e]{text-align:left}

[data-v-250670dd]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.num-total[data-v-250670dd]{padding-left:15px}.num-total>.el-col[data-v-250670dd]{border:1px solid #ededed;padding:15px 20px;padding-left:30px !important;border-radius:2px}.num-total>.el-col h4[data-v-250670dd]{font-size:30px}.num-total>.el-col p[data-v-250670dd]{margin-top:5px}.num-total>.el-col:nth-child(2) h4[data-v-250670dd]{color:green}.num-total>.el-col:last-child h4[data-v-250670dd]{color:red}.num-total .el-col+.el-col[data-v-250670dd]{margin-left:10px}[data-v-250670dd] .el-tabs__content{padding-left:50px !important}

[data-v-8ea44cc8]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.num-total[data-v-8ea44cc8]{padding-left:15px}.num-total>.el-col[data-v-8ea44cc8]{border:1px solid #ededed;padding:15px 20px;border-radius:2px}.num-total>.el-col h4[data-v-8ea44cc8]{font-size:30px}.num-total>.el-col h4 span[data-v-8ea44cc8]{font-size:14px;font-weight:normal}.num-total>.el-col p[data-v-8ea44cc8]{margin-top:5px}.num-total .el-col+.el-col[data-v-8ea44cc8]{margin-left:10px}[data-v-8ea44cc8] .el-tabs__content{padding-left:50px !important}

[data-v-16d67eca]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.num-total[data-v-16d67eca]{padding-left:15px}.num-total>.el-col[data-v-16d67eca]{border:1px solid #ededed;padding:15px 20px;border-radius:2px}.num-total>.el-col h4[data-v-16d67eca]{font-size:30px}.num-total>.el-col h4 span[data-v-16d67eca]{font-size:14px;font-weight:normal}.num-total>.el-col p[data-v-16d67eca]{margin-top:5px}.num-total .el-col+.el-col[data-v-16d67eca]{margin-left:10px}.circle-span[data-v-16d67eca]{display:inline-block;width:15px;height:15px;line-height:15px;text-align:center;background:#9e9e9e;border-radius:50%;color:#fff;font-size:12px;margin-left:5px}[data-v-16d67eca] .el-tabs__content{padding-left:50px !important}

[data-v-e41e8ab2]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.bom[data-v-e41e8ab2]{min-width:1440px;z-index:1}.bom .bom-item[data-v-e41e8ab2]{border:1px solid #ebeef5;min-width:420px;padding:0px 0px 0px 10px;margin-bottom:20px}.bom .bom-item .bom-name[data-v-e41e8ab2]{height:50px;line-height:50px;padding:0px 10px;display:flex;align-items:center}.bom .bom-item[data-v-e41e8ab2]  .el-timeline{height:450px;overflow-y:scroll}.bom .bom-item[data-v-e41e8ab2]  .el-timeline-item__node{border:1px solid #2882ff;background:#fff}.bom .bom-item[data-v-e41e8ab2]  .el-timeline-item__node--normal{left:0}[data-v-e41e8ab2] .el-image__inner{height:40px !important;width:40px !important}

[data-v-3301255c]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.dialog-content .el-form[data-v-3301255c]{width:95%;margin:0 auto}[data-v-3301255c] .el-select{width:100%}.text-hidden[data-v-3301255c]{width:150px}

[data-v-1a1b7ba4]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}[data-v-1a1b7ba4] .dialog-footer{display:flex;justify-content:center}

[data-v-c3483210]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.msg-box[data-v-c3483210]{display:inline-block}

[data-v-bb49497c]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.offer-remark[data-v-bb49497c]{display:inline-block}

[data-v-20519f7a]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}[data-v-20519f7a] .table-custom{height:100% !important}[data-v-20519f7a] .table-custom .el-table{height:100% !important}[data-v-20519f7a] .table-fixed{height:580px !important}

[data-v-3ccef944]:export{menu-color:rgba(255,255,255,0.95);menu-color-active:rgba(255,255,255,0.95);menu-background:#282c34;column-second-menu-background:#fff}.pro-container[data-v-3ccef944]{height:100%;overflow:hidden;position:relative;overflow-y:hidden !important}.pro-container .fix-msg-operate h6[data-v-3ccef944]{font-size:14px}.pro-container .fix-msg-operate p label[data-v-3ccef944]{color:#999}.pro-container .fix-msg-operate p span[data-v-3ccef944]{color:#000}.pro-container .tabs-content[data-v-3ccef944]{padding:8px 20px 0;height:calc(100% - 44px)}[data-v-3ccef944] .el-tabs--border-card{box-shadow:0 0 0 0 #000}[data-v-3ccef944] .el-tabs{height:100%}[data-v-3ccef944] .el-tabs__header{margin:0 0 20px}[data-v-3ccef944] .el-tabs__content{height:calc(100% - 59px);overflow-y:auto}[data-v-3ccef944] .el-page-header{margin:0 !important}.page-header[data-v-3ccef944]{padding:0 20px}.page-header .page-header-line[data-v-3ccef944]{border-bottom:1px solid #ebeef5;padding-bottom:13px;padding-top:4px}


/*# sourceMappingURL=chunk-2bd23157.fba494f4.css.map*/